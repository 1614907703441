import React, { useState } from 'react';

import './Html5Qrcod.css';

import Html5QrcodePlugin from './QR Code/Html5QrcodePlugin';
import ResultContainerPlugin from './QR Code/ResultContainerPlugin';

function App() {
    const [decodedResults, setDecodedResults] = useState([]);

    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        // console.log(`Code matched = ${decodedText}`, decodedResult);
        console.log("App [result]", decodedResult);
        setDecodedResults(prev => [...prev, decodedResult]);
    };

    return (
        <div className="App">
            <section className="App-section">
                <h1 className="App-section-title"> Html5-qrcode React demo</h1>
                <br />
                <Html5QrcodePlugin
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
                <ResultContainerPlugin results={decodedResults} />

            </section>
        </div>
    );
}

export default App;
