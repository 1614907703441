import React, { useState } from 'react';

import Html5Qrcod from './Html5Qrcod';
function App() {


  return (
    <div className="App">
      <Html5Qrcod />
    </div>
  );
}

export default App;
